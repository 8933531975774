import React from "react";
import Footer from "../components/Footer"
import { Link } from "gatsby";
import { ChevronLeftIcon } from "@heroicons/react/outline"

export default function About() {
    return (
      <div>
      <div className="min-h-screen bg-gray-50 py-40 flex flex-col">
        <div className="hero-section">
          <div className="container mx-auto px-8 sm:px-48">
            <Link className="back-home flex items-center" to="/"><ChevronLeftIcon className="inline-block mr-1 h-4 w-4" /><span>Back to home</span></Link>
            <h1 className="lead-text text-7xl sm:text-8xl sm:leading-14">
              This is <span className="font-bold">humanz</span>
            </h1>
            <p className="info-text md:text-2xl text-gray-400">we hope, we're pronouncing it correctly!</p>
          </div>
        </div>
        <div className="normal-section mt-24">
          <div className="container mx-auto px-8 sm:px-48">
            <div className="content text-gray-500">
              <h5 className="md:text-xl font-bold uppercase mt-4">Who we are?</h5>
              <p className="para leading-7 mt-2">
                We know you're busy, so we'll get straight to the point. We are a group of <strong>humanz</strong> who specializes in designing and developing of digital web &amp; mobile apps. Our small team have an experience of more than 8 years and we provide IT services to our clients. We not only build products for the sake of it, we believe in innovation.
              </p>
              <h5 className="md:text-xl font-bold uppercase mt-20">What we do?</h5>
              <p className="para leading-7 mt-2">
                We can help you to unlock opportunity by creating <strong>humanz</strong>-centered products.
              </p>
              <div className="flex flex-col sm:flex-row">
                <div className="flex-col mt-4 sm:px-6">
                  <p className="para leading-7 mt-2">
                    <strong>Design</strong>
                  </p>
                  <ul>
                    <li>• <em>UI/UX Design</em></li>
                    <li>• <em>Interaction Design</em></li>
                    <li>• <em>Visual Design</em></li>
                    <li>• <em>Design Systems</em></li>
                    <li>• <em>Design QAs</em></li>
                  </ul>
                </div>
                <div className="flex-col mt-4 sm:px-6">
                  <p className="para leading-7 mt-2">
                    <strong>Technology</strong>
                  </p>
                  <ul>
                    <li>• <em>Frontend (React.js, Vue.js)</em></li>
                    <li>• <em>Backend (Node.js, Laravel)</em></li>
                    <li>• <em>React Native</em></li>
                    <li>• <em>iOS &amp; Android</em></li>
                    <li>• <em>QA Testing</em></li>
                  </ul>
                </div>
              </div>
              <h5 className="md:text-xl font-bold uppercase mt-20">Need to know more?</h5>
              <div className="mt-6">
                <a className="btn btn-primary" href="mailto:support@humanzstudioz.com">Email Us</a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
    )
  }